import HomePageGrid from "./homePageGrid"
import Footer from "./Footer";
import Navimage from './navimage';

const MainPage = function () {
    return(
     <div>
        <Navimage/>
        <HomePageGrid/>
       <Footer/>
    </div>
)}

export default MainPage;