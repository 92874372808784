import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { firestore } from "../firebase"; 
import "./navbar.css";
import { Link } from "react-router-dom";
import UserDetailsModal from "./UserDetailsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnet,
  faBoltLightning,
  faStream,
  // faQuestionCircle,
  faNewspaper,
  faBinoculars,
  faPersonBiking,
  faCrosshairs,
  faPersonHiking,
  faHouse,
  faChevronDown,
  faChevronUp,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const searchBarStyle = {
    position: "absolute",
    top: "50%",
    right: "10%",
    transform: "translateY(-50%)",
    display: "flex",
    alignItems: "center",
    marginRight: "-115px",
  };

  const logoStyle = {
    height: "40px",
    marginLeft: "20px",
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    const fetchUserProfilePicture = async () => {
      if (user) {
        try {
          const userDoc = await firestore.collection("users").doc(user.uid).get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            setProfilePicture(userData.profilePicture || null);
          }
        } catch (error) {
          console.error("Error fetching user profile picture:", error);
        }
      }
    };

    fetchUserProfilePicture();
  }, []);

  const handleMenuHover = () => {
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-left">
          <div>
            <div onMouseEnter={handleMenuHover}>
              <input type="checkbox" id="check" />
              <label for="check" style={{ cursor: "pointer" }}>
                <i className="bi bi-list menu" style={{ color: "#fff" }}></i>
              </label>
            </div>
            <div style={searchBarStyle}>
              {/* <li class="search-container">
                <input
                  type="text"
                  name="search"
                  placeholder="Search..."
                  class="search-input"
                  style={{ color: "#ffff" }}
                />
                <Link class="search-btn">
                  <FontAwesomeIcon icon={faSearch} />
                </Link>
              </li> */}
              {/* user profile image have to set if user updated the profile image otherwise this icon have to show */}
              <li style={{ marginTop: "0px", marginLeft: "20px" }}>
              {profilePicture ? (
                <img
                  src={profilePicture}
                  alt="Profile"
                  className="user-profile-image"
                  style={{ width: "40px", height: "40px", borderRadius: "50%",marginBottom:"0" }}
                  onClick={toggleModal}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faUserCircle}
                  style={{ fontSize: "40px", color: "#ffff" }}
                  onClick={toggleModal}
                />
                // <img
                // src="./images/dp.jpg"
                // alt="dp"
                // onClick={toggleModal}
                // className="user-profile-image"
                // style={{ width: "40px", height: "40px", borderRadius: "50%",marginBottom:"0" }}
                // />


              )}
              {showModal && <UserDetailsModal />}
            </li>
            </div>

            {isSidebarOpen && (
              <div className="sidebar" onMouseLeave={handleSidebarClose}>
                <Link to="/home" className="menu_links">
                  <FontAwesomeIcon icon={faHouse} />
                  <span style={{ marginLeft: "10px" }}>Home</span>
                </Link>
                <Link to="/news&update" className="menu_links">
                  <FontAwesomeIcon icon={faNewspaper} />
                  <span style={{ marginLeft: "10px" }}>News & updates</span>
                </Link>
                <Link to="/social" className="menu_links">
                  <FontAwesomeIcon icon={faCrosshairs} />
                  <span style={{ marginLeft: "10px" }}>
                    Social Huntsman optics
                  </span>
                </Link>
                <hr style={{margin:0,padding:"5px",height:"10px",color:"#c21b29"}}></hr>
                <Link
                  className="menu_links"
                  onClick={toggleDropdown}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FontAwesomeIcon icon={faStream} />
                  <span style={{ marginLeft: "10px" }}>Business Sub-brand</span>
                  <FontAwesomeIcon
                    icon={isDropdownOpen ? faChevronUp : faChevronDown}
                    style={{ marginLeft: "25px", verticalAlign: "middle" }}
                  />
                </Link>
                {isDropdownOpen && (
                  <div className="dropdown-content">
                    {/* Add your dropdown content here */}
                    <Link to="/HuntsmanThermo" className="menu_links">
                      <FontAwesomeIcon icon={faBinoculars} />
                      <span style={{ marginLeft: "10px" }}>
                        Huntsman Thermography{" "}
                      </span>
                    </Link>
                    <Link to="/CoastOutdoor" className="menu_links">
                      <FontAwesomeIcon icon={faPersonBiking} />
                      <span style={{ marginLeft: "10px" }}>Coast Outdoor</span>
                    </Link>
                    <Link to="/DemoDeals" className="menu_links">
                      <FontAwesomeIcon icon={faPersonHiking} />
                      <span style={{ marginLeft: "10px" }}>
                        Outdoor demo deals
                      </span>
                    </Link>
                    <Link to="/SperosFlashlight" className="menu_links">
                      <FontAwesomeIcon icon={faBoltLightning} />
                      <span style={{ marginLeft: "10px" }}>
                        SperasFlashlights
                      </span>
                    </Link>
                    <Link to="/MagneTech" className="menu_links">
                      <FontAwesomeIcon icon={faMagnet} />
                      <span style={{ marginLeft: "10px" }}>
                      Magne Tech
                      </span>
                    </Link>
                  </div>
                )}
                {/* <Link to="/MagneTech" className="menu_links">
                  <FontAwesomeIcon icon={faQrcode} />
                  <span style={{ marginLeft: "10px" }}>MAGNE TECH</span>
                </Link> */}
                {/* <Link className="menu_links">
                  <FontAwesomeIcon icon={faQrcode} />
                  <span style={{ marginLeft: "10px" }}>Contact us</span>
                </Link> */}
              </div>
            )}
          </div>

          <Link to="/home"><img src="./images/logo_02.png" style={logoStyle} alt="logo" /></Link>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
