import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img 
        src="/images/vadivel-not-found.png" 
        alt="Page Not Found" 
        className="not-found-image" 
      />
      <h1 className="not-found-text">Page Not Found</h1>
      <Link to="/home" className="not-found-link">
        Go to Home Page
      </Link>
    </div>
  );
};

export default NotFound;
