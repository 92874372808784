import React from "react";
import "./DriveMain.css";
import Footer from "./Footer";

const DriveMain = () => {
  return (
    <div>
    <div className="drive-main-container">
      {/* Card 1 */}
      <div className="drive-main-card">
        <div className="drive-main-card-content">
        <a href="https://drive.google.com/drive/folders/0AEfaNpkcfA9OUk9PVA"  target="blank" >
          <img src="/images/black-folder.png" alt="Card 1" className="drive-main-card-image" />
          <p className="drive-main-card-title">HO Internal</p>
        </a>

        </div>
      </div>

      {/* Card 2 */}
      <div className="drive-main-card">
        <div className="drive-main-card-content">
          <a href="https://drive.google.com/drive/folders/0AGopnHIh-NLjUk9PVA" target="blank" >
          <img src="/images/black-folder.png" alt="Card 2" className="drive-main-card-image" />
          <p className="drive-main-card-title">Thermal Footage influencers</p>
          </a>
        </div>
      </div>

      {/* Card 3 */}
      <div className="drive-main-card">
        <div className="drive-main-card-content">
          <a href="https://drive.google.com/drive/folders/0AFuQfGlup-ezUk9PVA" target="blank" >
          <img src="/images/black-folder.png" alt="Card 3" className="drive-main-card-image" />
          <p className="drive-main-card-title">HO External drive for customers</p>
          </a>
        </div>
      </div>
    </div>
    <Footer/>
</div>
  );
};

export default DriveMain;
