import React from 'react';
import './DriveNavbar.css';
import { Link } from 'react-router-dom';

const DriveNavbar = () => {
  return (
    <nav className="drive-nav">
      <div className="drive-wrapper">
        <Link to="/home">
          <img
            src="./images/logo_02.png"
            style={{ height: "40px", marginLeft: "20px" }}
            alt="logo"
          />
        </Link>
        <ul className="drive-nav-links">
          <li>
            <Link to="/DriveMain"> Main Drive </Link>
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/folders/0AGopnHIh-NLjUk9PVA"
              target="_blank"
              rel="noopener noreferrer"
            >
              Thermal Footage Influencers
            </a>
          </li>
          <li>
            <span className="drive-desktop-item">
              HO Internal
            </span>
            <ul className="drive-drop-menu">
              <li>
                <a
                  href="https://drive.google.com/drive/folders/1-ovlEUCAc4dTYYx5bJwmQoqoaM7jh44r"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HO Website Tools
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/drive/folders/1il1jgGukwrhckWrXqWswdnAfN4UJoZII"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HO-User Guides
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/drive/folders/1tNr7FjcDYMB5Xs19jHPBsMWDcf1XMWk2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HO-Products Ranges-Inventory Control
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/drive/folders/1i-IeQFRuucU6MHruJCCWiXa4LlJ3p31G"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HO-Marketing For Everything
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/drive/folders/1AgQEFP2br5buKUy6G8aJhfWE7ImlKhhn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HO-HMRMA Repairs-Fault Claims
                </a>
              </li>
            </ul>
          </li>
          <li>
          <span className="drive-desktop-item">
            HO External Drive for Customers
            </span>
            <ul className="drive-drop-menu">
              <li>
                <span className="drive-desktop-item">
                  HO Customer Dealer
                </span>
                <ul className="drive-nested-dropdown">
                  <li>
                    <a
                      href="https://drive.google.com/drive/folders/1wDCQbY6ZbI1pnG5xk30YTMLeo7HfqSz7?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Online Assets
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://drive.google.com/drive/folders/1KEDAW5Jv3RXaNCtR4B9e6Ki7TUQaPLyM?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Offline Assets
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <span className="drive-desktop-item">
                  HO Price List
                </span>
                <ul className="drive-nested-dropdown">
                  <li>
                    <a
                      href="https://docs.google.com/spreadsheets/d/1Bvf248HxoUFFN23DEA1VU2wGBla2IvOuKiramYlnbsU/edit?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      New Zealand-GFX
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.google.com/spreadsheets/d/1DMCdgtofDFZxcD10uJu-PG5_hwq58fADITBtqAbr2SE/edit?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Australian-GFX
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="https://drive.google.com/drive/folders/1_sguKQ36eUJgzOpWlH9EZsf1zD20cmAd?usp=drive_link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Social Media Sharing
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/drive/folders/1TfWif24UFFUUN-xfA0Rv6nEmAeKfsZtC?usp=drive_link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube Videos for Retailers
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default DriveNavbar;
