import "./HomePageGrid.css";
import React from "react";
import { Link } from "react-router-dom";

const HomePageGrid = function () {
    return (
        <div>
            <div className="container main-cent">
                <div className="cent">
                    <div className="market">
                        <h2 className="under">Marketing</h2>
                        <hr style={{ margin: "0",borderTop:"5px solid #ddd" }}></hr>
                        <div className="row no-gutters mt-5 home_row">
                            {/*1st  Single Row with 7 Columns */}
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href="https://drive.google.com/drive/folders/1jSN_rQl_WScCN-UGEJck249AykvBsbaO?usp=drive_link" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/socialM.png" alt="Internal Drive" />
                                        </a>
                                        <h4 className="card-title">Online Marketing</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href=" https://drive.google.com/drive/folders/1b7zNvTKx7DG50S3SzTpEO8llIqTDfeEv?usp=drive_link" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/onsite.png" alt="HO Assets" />
                                        </a>
                                        <h4 className="card-title">Onsite Marketing</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href=" https://drive.google.com/drive/folders/1HlXHyZTzhUjCvOoP-_JOHkgV641Cz2RB?usp=drive_link" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/off.png" alt="Hikmicro Thermal" />
                                        </a>
                                        <h4 className="card-title">Offline Marketing Inc Assets</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href="https://hikmicrotech.app.box.com/s/dfdw4twyw1u3dimyeeg4e8imi12h8jba/folder/153066988708?fbclid=IwAR2GPRY9qRkYiDYg1GGpuYuhTw7d00ygY3WNDJSWd3uMb7crAhCgJVAdfYc " target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/educationalvedio.png" alt="Hikmicro Thermal" />
                                        </a>
                                        <h4 className="card-title">Hikmicro Outdoor Public</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href=" https://drive.google.com/drive/folders/1bt80D2SltzkAqIZOQCFn47WnEhrrZAM9?usp=drive_link" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/targetmarket.png" alt="HO Assets" />
                                        </a>
                                        <h4 className="card-title">HO Influencers</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href="https://mailchimp.com/landers/email-marketing-platform/?ds_c=DEPT_AOC_Google_Search_AU_EN_Brand_Acquire_Exact_MKAG_AU&ds_kids=p74937093191&ds_a_lid=kwd-2285511033&ds_cid=71700000105319282&ds_agid=58700008248580181&gad_source=1&gclid=Cj0KCQjw0ruyBhDuARIsANSZ3wr7C7p4_E1qGIuagGi2RtlXo7nCQY3uaNVxcqvTXfGk2dvB3slF4fYaAv09EALw_wcB&gclsrc=aw.ds&currency=AUD" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/mc.jpg" alt="mailchimp" />
                                        </a>
                                        <h4 className="card-title">Mailchimp</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href="https://drive.google.com/drive/folders/1i-IeQFRuucU6MHruJCCWiXa4LlJ3p31G?usp=drive_link" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/internalM.jpg" alt="mailchimp" />
                                        </a>
                                        <h4 className="card-title">Internal Marketing Folder</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href="https://docs.google.com/spreadsheets/d/1DMCdgtofDFZxcD10uJu-PG5_hwq58fADITBtqAbr2SE/edit?usp=sharing" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/aus.jpg" alt="mailchimp" />
                                        </a>
                                        <h4 className="card-title">Australia HO Prices</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href="https://docs.google.com/spreadsheets/d/1Bvf248HxoUFFN23DEA1VU2wGBla2IvOuKiramYlnbsU/edit?usp=sharing" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/newzland.jpg" alt="mailchimp" />
                                        </a>
                                        <h4 className="card-title">New Zealand HO Prices</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sales">
                    <h2 className="under">Sales</h2>
                    <hr style={{ margin: "0",borderTop:"5px solid #ddd" }}></hr>
                    <div className="row no-gutters mt-5 home_row">
                        {/* 2nd row */}
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href="https://au.unleashedsoftware.com/v2/Account/LogOn?ReturnUrl=%2fv2%2f" target="_blank" rel="noreferrer">
                                        <img height="100px" width="100px" src="./images/unleashedc.png" alt="Unleashed" />
                                    </a>
                                    <h4 className="card-title">Unleashed</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href="https://huntsmanopticsau.store.unleashedsoftware.com/home" target="_blank" rel="noreferrer">
                                        <img height="100px" width="100px" src="./images/b2blightblue.png" alt="B2B Portal" />
                                    </a>
                                    <h4 className="card-title">B2B Portal</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href="https://www.huntsmanoptics.com/au/" target="_blank" rel="noreferrer">
                                        <img height="100px" width="100px" src="./images/aim.jpg" alt="Huntsman Webpage" />
                                    </a>
                                    <h4 className="card-title">Huntsman Webpage</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href=" https://auwebship.inxpress.com/imcs_au/login" target="_blank" rel="noreferrer">
                                        <img height="100px" width="100px" src="./images/webship.png" alt="B2B Portal" />
                                    </a>
                                    <h4 className="card-title">Inexpress-Webship</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href="https://forms.gle/N7T7ciCNyvFDX7568" target="_blank" rel="noreferrer">
                                        <img height="100px" width="100px" src="./images/req.png" alt="B2B Portal" />
                                    </a>
                                    <h4 className="card-title">Stock Return Request</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    {/* <a href="" target="_blank" rel="noreferrer"> */}
                                    <Link to="/DealerLocate">
                                        <img height="100px" width="100px" src="./images/dealer.jpg" alt="B2B Portal" />
                                    </Link>

                                    {/* </a> */}
                                    <h4 className="card-title">Dealer Locator</h4>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href="https://www.google.com/maps/d/edit?mid=1OllGBdHpIpSFEO3IRSrfEGTeridK3D0&ll=-34.84650814552343%2C144.24974211227115&z=7" target="_blank" rel="noreferrer">
                                        <img height="100px" width="100px" src="./images/salesmap.png" alt="B2B Portal" />
                                    </a>
                                    <h4 className="card-title">HO TNVS Stores</h4>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href="https://drive.google.com/drive/folders/1VfB-hCpzLrTf_7kE3x--p25V5GdeCFCt?usp=drive_link" target="_blank" rel="noreferrer">
                                        <img height="100px" width="100px" src="./images/customerdb.jpg" alt="B2B Portal" />
                                    </a>
                                    <h4 className="card-title">Customer Database</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="oso">
                    <h2 className="under">Internals</h2>
                    <hr style={{ margin: "0",borderTop:"5px solid #ddd" }}></hr>
                    <div className="row no-gutters mt-5 home_row"style={{justifyContent:"left"}}>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href="  https://drive.google.com/drive/folders/1il1jgGukwrhckWrXqWswdnAfN4UJoZII?usp=drive_link" target="_blank" rel="noreferrer">
                                        <img height="100px" width="120px" src="./images/userGuide.jpg" alt="OSO Home" />
                                    </a>
                                    <h4 className="card-title">User Guides</h4>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href="https://docs.google.com/spreadsheets/d/1F1CDTPk7dGhbD8MpUPPHTgIv8Xfh0EYOhW3rMApb5uU/edit?usp=sharing" target="_blank" rel="noreferrer">
                                        <img height="100px" width="120px" src="./images/spreadsheet.png" alt="OSO Products" />
                                    </a>
                                    <h4 className="card-title">Stock Return Spreadsheet</h4>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href=" https://drive.google.com/drive/folders/1tNr7FjcDYMB5Xs19jHPBsMWDcf1XMWk2?usp=drive_link" target="_blank" rel="noreferrer">
                                        <img height="100px" width="120px" src="./images/range-icon-16.png" alt="OSO Admin" />
                                    </a>
                                    <h4 className="card-title">Products Ranges</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <a href=" https://drive.google.com/drive/folders/1iUuOJ58X7KaiysuqMFaN6eUXEokfx-hl?usp=drive_link" target="_blank" rel="noreferrer">
                                        <img height="100px" width="120px" src="./images/XA-products.png" alt="OSO Backend" />
                                    </a>
                                    <h4 className="card-title">Unleash User Guide</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    {/* <a href=" https://docs.google.com/spreadsheets/d/1-hjKY843U8WabVUFAk6Q2IEoUcyAUiRT/edit?usp=sharing&ouid=102240000970897040050&rtpof=true&sd=true" target="_blank" rel="noreferrer"> */}
                                    <a href="https://forms.gle/oQcWxDukN8UcQkt49" target="_blank" rel="noreferrer">
                                        <img height="100px" width="120px" src="./images/graphic.png" alt="OSO Backend" />
                                    </a>
                                    <h4 className="card-title">Graphic Request Form</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                            <div className="card home_card">
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    <Link to='/DriveMain'>
                                    <img height="100px" width="120px" src="./images/drive.jpg" alt="drive-logo" /></Link>
                                    <h4 className="card-title">Drive</h4>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePageGrid;
